import { DateTime } from 'luxon';

export const dateTimeOrStringToDate = (date: Date | string) => {
  if (date instanceof Date) return date;
  return new Date(date);
};

export const isOlderThanXDays = (dateStr: string, days: number): boolean => {
  const givenDate = DateTime.fromISO(dateStr);
  if (!givenDate.isValid) {
    throw new Error(`Invalid date format: ${dateStr}`);
  }

  const diffInDays = DateTime.now().diff(givenDate, 'days').days;
  return diffInDays > days;
};

export const dateOrNextBusinessDay = (date: DateTime): DateTime => {
  if (date.weekday === 7) {
    return date.plus({ days: 1 });
  }
  if (date.weekday === 6) {
    return date.plus({ days: 2 });
  }
  return date;
};

export const luxonFormatOptions = {
  //make sure to call setLocale before passing this to toFormat
  dayAndShortMonth: {
    fr: 'd MMM',
    en: 'MMM. d',
  },
  dayAndShortMonthWithYear: {
    fr: 'd MMM y',
    en: 'MMM. d y',
  },
};
